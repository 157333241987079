import capitalize from './capitalize';

export default (text) => {
  const breadcrumbIsUuid = text.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  );
  const breadcrumbIsMongoID = text.match(/^[a-f\d]{24}$/i);

  if (breadcrumbIsUuid || breadcrumbIsMongoID) return text;

  return decodeURIComponent(
    text.replace(/-+/g, ' ').split(' ').map(capitalize).join(' ')
  );
};
