export const networks = [
  { name: '3', id: '96ef03d9-ae5e-4ee6-96aa-9dd207274b77', territory: 'GB' },
  { name: 'EE', id: 'c0cdf313-bc17-4696-9236-2c41e1847b38', territory: 'GB' },
  { name: 'O2', id: '6efa2e06-3607-46c5-a722-6da971295edf', territory: 'GB' },
  {
    name: 'Vodafone',
    id: 'e75e2957-fccf-41eb-9b25-33cf5121dfd7',
    territory: 'GB',
  },
  {
    name: '1PMobile',
    id: '50bfbe80-1913-4afe-9921-d115394efaa8',
    territory: 'GB',
  },
  {
    name: '360Coms Telecom',
    id: 'a9807460-6113-4b3a-a797-2985fed19a0c',
    territory: 'GB',
  },
  {
    name: 'AfriMobile',
    id: '49bf5dd3-93e0-4d0d-988e-554a3e892f50',
    territory: 'GB',
  },
  {
    name: 'Age Uk My Phone',
    id: 'b4905c0f-970c-47b1-bb5b-f94b742cfb1a',
    territory: 'GB',
  },
  {
    name: 'Airwave Smart Mobile',
    id: '13a510cb-3e57-40ff-994d-50ba194406ec',
    territory: 'GB',
  },
  {
    name: 'Anywhere Sim',
    id: '78a8b406-a2a1-41df-91c1-cb0794cb7a6d',
    territory: 'GB',
  },
  {
    name: 'Asda Mobile',
    id: '5a75688a-c92b-41d8-8c45-4e7d92fe867c',
    territory: 'GB',
  },
  {
    name: 'Auracall Travel Talk',
    id: 'b222b37f-d33e-4773-a34e-b338550f81ff',
    territory: 'GB',
  },
  {
    name: 'Axis Telecom',
    id: '66436cfc-6f53-44ea-bc0e-15097589ec1e',
    territory: 'GB',
  },
  {
    name: 'BT Mobile',
    id: 'dfc869d3-0722-4c96-be23-5b51fce749c0',
    territory: 'GB',
  },
  {
    name: 'C4C Mobile',
    id: '5b93eaea-f65a-48de-8aa6-f181ed0f5e21',
    territory: 'GB',
  },
  {
    name: 'Candy Telecom',
    id: '46739f21-d2e0-41b5-8c62-0894c4f6005b',
    territory: 'GB',
  },
  {
    name: 'Champions Mobile',
    id: '473a86dc-ac7a-48dd-b131-efed17434e80',
    territory: 'GB',
  },
  {
    name: 'Chromebook 3G Sim',
    id: '49ef69d7-8ef7-4a7a-bdc6-091593985c39',
    territory: 'GB',
  },
  {
    name: 'CTExcelbiz',
    id: '235ac432-ca6c-48ab-953e-0aa0acaa28c6',
    territory: 'GB',
  },
  {
    name: 'Delight Mobile',
    id: '6208b4e5-1348-4ef8-a0cc-7db5241e1f5f',
    territory: 'GB',
  },
  {
    name: 'Digital Phone',
    id: '68c0f2ea-b9eb-47c7-b9cd-afaccfbfe6b7',
    territory: 'GB',
  },
  {
    name: 'Econet Mobile',
    id: '1369b0e7-d7fe-457d-a1e8-bc1a004f3080',
    territory: 'GB',
  },
  {
    name: 'EcoTalk',
    id: 'b49e64e9-5126-45b3-9e7a-f2d59db51e2a',
    territory: 'GB',
  },
  {
    name: 'Fonome Mobile',
    id: '6b8a0804-586d-45fa-9ae2-d94cfc0cf783',
    territory: 'GB',
  },
  {
    name: 'FreedomPop',
    id: 'c208a0e0-5cec-4b38-9653-91c71a0b8927',
    territory: 'GB',
  },
  {
    name: 'Gamma Telecom',
    id: 'dc499a5c-8ba1-4e93-a7c2-e18f1f932de0',
    territory: 'GB',
  },
  {
    name: 'GiffGaff',
    id: 'edae0f5b-8f55-4775-8de1-ee561dc07de7',
    territory: 'GB',
  },
  {
    name: 'Go Mobile',
    id: '745a5729-c0e4-467b-ad36-3dc6693e0b94',
    territory: 'GB',
  },
  {
    name: 'GT Mobile',
    id: '8150a312-a470-48c8-bcd6-63799969d796',
    territory: 'GB',
  },
  {
    name: 'HP Mobile Connect',
    id: '3b448927-1b3f-422b-bc56-fc745e99049c',
    territory: 'GB',
  },
  {
    name: 'ID Mobile',
    id: '6afc8233-db3d-4dac-999e-d97293e0057f',
    territory: 'GB',
  },
  { name: 'Jump', id: '40abe112-df72-45a6-af31-5de692441aff', territory: 'GB' },
  {
    name: 'KC Mobile',
    id: 'b1e4230e-7523-4161-bee6-de33541d7c72',
    territory: 'GB',
  },
  {
    name: 'Kingdom Mobile',
    id: '23aee699-b6cc-40c7-9f56-0405c306d134',
    territory: 'GB',
  },
  {
    name: 'Krisant Telecom',
    id: '9a25016c-9d7d-43a2-825b-624f818b11a7',
    territory: 'GB',
  },
  {
    name: 'Lebara Mobile',
    id: '3c3f1c06-caad-41ac-a472-b440c4d14d8b',
    territory: 'GB',
  },
  {
    name: 'Lomo Mobile',
    id: '0cadc592-763d-4604-86c4-d931ad6746cc',
    territory: 'GB',
  },
  {
    name: 'Lycamobile',
    id: '68fd16de-ced5-46fe-abe8-3eabbec0d0d4',
    territory: 'GB',
  },
  {
    name: 'Matrix Cellular',
    id: '7c1c8a84-21f9-4fa5-b81f-55a66ba83803',
    territory: 'GB',
  },
  {
    name: 'Meem Mobile',
    id: '1730de4a-6269-4f93-ac54-543065035e51',
    territory: 'GB',
  },
  {
    name: 'Mobilek',
    id: '173475de-ae67-4547-816f-803eb8572142',
    territory: 'GB',
  },
  { name: 'Mtel', id: '4f8684c5-4bf6-4d4d-9483-687d276a1ff9', territory: 'GB' },
  {
    name: 'Natterbox',
    id: '391a2663-e44d-440b-8cd4-e1576b5ebbc9',
    territory: 'GB',
  },
  {
    name: 'NordTelekom',
    id: '965d6ca9-e375-452f-85c2-6c9c265d7b26',
    territory: 'GB',
  },
  {
    name: 'Now PAYG',
    id: 'e965c5cb-56dd-4724-899c-0ce0dd948ce1',
    territory: 'GB',
  },
  {
    name: 'Orange',
    id: '1a8b8c88-da98-4621-9837-47d0d5a5b36f',
    territory: 'GB',
  },
  {
    name: 'Peeble Mobile Network',
    id: 'ab7503e2-d8dd-46e8-a1e6-1328ed68ec4c',
    territory: 'GB',
  },
  {
    name: 'PlusNet Mobile',
    id: '14ea71c5-7742-47b6-8014-f9a661eac373',
    territory: 'GB',
  },
  {
    name: 'ROK Mobile',
    id: '50aecbba-6eef-4856-b252-74bd44e39cba',
    territory: 'GB',
  },
  {
    name: 'RWG Mobile',
    id: '2c64bf8d-20c2-4725-b388-4c798a46601d',
    territory: 'GB',
  },
  {
    name: 'Shebang',
    id: '453b3c4c-6b87-4843-8f53-5f9d6d5e881b',
    territory: 'GB',
  },
  {
    name: 'Simwood',
    id: '2e95924b-68cf-4235-923e-ef945b17d902',
    territory: 'GB',
  },
  { name: 'Sky', id: '62c8699c-77b4-4b8a-9af5-61892582424a', territory: 'GB' },
  {
    name: 'Smarty',
    id: 'f8eab79b-31c3-440e-b569-236a935f2f10',
    territory: 'GB',
  },
  {
    name: 'Superdrug Mobile',
    id: 'c87d5718-3803-4884-bd50-5d8ae581453c',
    territory: 'GB',
  },
  {
    name: 'T Mobile',
    id: '1c73dacd-10a2-434d-86ef-d677cc53d1e7',
    territory: 'GB',
  },
  {
    name: 'Talk Home Mobile',
    id: '4e74dbda-4b05-4fde-9145-a543dcf9fa07',
    territory: 'GB',
  },
  {
    name: 'Talkmobile',
    id: '88116049-e029-49a2-9e39-a31d1d2138c5',
    territory: 'GB',
  },
  {
    name: 'TalkTalk Mobile',
    id: 'd9cd6dc1-2ab2-4c34-bf5e-d4d04705f5fb',
    territory: 'GB',
  },
  {
    name: 'Telecom Plus',
    id: '388befe5-0675-4715-b3ef-fd7ef72ee1f9',
    territory: 'GB',
  },
  {
    name: 'Telfoni',
    id: '89d09815-b2d7-4187-af7d-f9f8f964fc45',
    territory: 'GB',
  },
  {
    name: 'Tello',
    id: 'c2610c4d-b651-46ea-aea8-83856d7fc2cd',
    territory: 'GB',
  },
  {
    name: 'Tesco Mobile',
    id: 'a9d8a6c3-c02b-44d6-8acc-73bb296e53c7',
    territory: 'GB',
  },
  {
    name: 'The Peoples Network',
    id: '83065277-e2b5-4907-aea2-0cd415cfb073',
    territory: 'GB',
  },
  {
    name: 'The Phone Co-op',
    id: '59e79c7e-c64d-4e74-a86b-2097a63d1cd8',
    territory: 'GB',
  },
  {
    name: 'Tismi',
    id: 'bf9b0f00-2db7-4315-81fb-a336ff9ed533',
    territory: 'GB',
  },
  {
    name: 'Truphone',
    id: 'b75117b7-b7bc-48be-bf13-093968f4535b',
    territory: 'GB',
  },
  {
    name: 'U2I Mobile',
    id: '8260b318-26e4-42dd-856e-64de1e92c905',
    territory: 'GB',
  },
  {
    name: 'Utility Warehouse',
    id: 'b77910c7-2c6b-45b7-85ff-98b6010cb875',
    territory: 'GB',
  },
  {
    name: 'Vectone Mobile',
    id: '78d5cad3-cc9a-4ea4-9290-5e4a9d0b2d29',
    territory: 'GB',
  },
  {
    name: 'Virgin Mobile',
    id: '3fd3c349-0d15-41c8-b6a7-605fc9d8d5fc',
    territory: 'GB',
  },
  {
    name: 'Voip.co.uk',
    id: '16b48789-4277-41b8-81d8-ac148212e693',
    territory: 'GB',
  },
  { name: 'VOXI', id: '5a2906c4-f235-4063-bf24-8a6281bbfcb9', territory: 'GB' },
  {
    name: 'White Mobile',
    id: 'c2fea0cd-df8c-4eb8-98fc-15de3c7665f3',
    territory: 'GB',
  },
  { name: 'EIR', id: 'ca9af59c-b539-4b44-b45b-e08497538ea6', territory: 'IE' },
  {
    name: '3',
    id: 'd1834fa7-73dc-4810-b72e-95cedc173a69',
    territory: 'IE',
  },
  {
    name: 'Vodafone',
    id: 'cc29092c-94cc-4da3-8f65-270fa28219ad',
    territory: 'IE',
  },
  {
    name: '48',
    id: '8aae55b5-e703-4888-a73e-a0bf25a4399e',
    territory: 'IE',
  },
  {
    name: 'Blueface',
    id: '1a5dee4a-9cf1-4e84-ad2a-2fe88462f773',
    territory: 'IE',
  },
  {
    name: 'ID Mobile',
    id: 'a08aa54c-b36a-40fa-9912-627a32f30a27',
    territory: 'IE',
  },
  {
    name: 'Lycamobile',
    id: '66d327df-ec7a-4521-bf30-157ed63c86a2',
    territory: 'IE',
  },
  {
    name: 'Permanet',
    id: 'cebfe620-615e-437d-b9e7-28a041094371',
    territory: 'IE',
  },
  {
    name: 'Post Mobile',
    id: '3109e6c0-ef2f-4bb8-9cc3-2ded24860bd9',
    territory: 'IE',
  },
  {
    name: 'Tesco Mobile',
    id: '7f63cd19-f741-47d2-acec-8b6b0864e027',
    territory: 'IE',
  },
  {
    name: 'Virgin Mobile',
    id: '5ed3525a-9995-459b-90c8-619edcab79d9',
    territory: 'IE',
  },
].map(({ name, territory, id }) => ({
  label: `${name} (${territory})`,
  value: id,
}));
