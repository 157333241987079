const SET_AUTH = 'SET_AUTH';
const INITIAL_STATE = {
  token: '',
};

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_AUTH':
      return { token: action.payload.token };
    default:
      return state;
  }
}

export function setAuth(auth) {
  localStorage.setItem('v1_token', auth.token);

  return { type: SET_AUTH, payload: auth };
}
