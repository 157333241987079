import * as constants from '../constants';

export const missingTransactionTags = [
  {
    text: 'MID not provisioned',
    value: constants.API_RESPONSE_MID_NOT_PROVISIONED,
    color: 'red',
  },
  {
    text: 'Outlet information unknown',
    value: constants.API_RESPONSE_OUTLET_INFORMATION_UNKNOWN,
    color: 'cyan',
  },
  {
    text: 'Query Transaction',
    value: constants.API_RESPONSE_QUERY_TRANSACTION,
    color: 'purple',
  },
  {
    text: 'Aged - write off',
    value: constants.API_RESPONSE_AGED_WRITE_OFF,
    color: 'blue',
  },
  {
    text: 'Admin error - write off',
    value: constants.API_RESPONSE_ADMIN_ERROR_WRITE_OFF,
    color: 'green',
  },
  {
    text: 'MID not tracking',
    value: constants.API_RESPONSE_MID_NOT_TRACKING,
    color: 'gold',
  },
  {
    text: 'Queried with user',
    value: constants.API_RESPONSE_QUERIED_WITH_USER,
    color: 'magenta',
  },
  {
    text: 'MID previously tracked',
    value: constants.API_RESPONSE_MID_PREVIOUSLY_TRACKED,
    color: 'lime',
  },
];
