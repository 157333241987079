const themeConstants = {
  borderRadius: 16,
  colours: {
    airBlue: '#7DB9FF',
    eclipseBlackDark: '#121212',
  },
};

export default {
  token: {
    borderRadiusSM: themeConstants.borderRadius,
    colorLink: themeConstants.colours.airBlue,
    colorPrimary: themeConstants.colours.airBlue,
    colorText: 'black',
    fontFamily: 'BricolageGrotesque',
  },
  components: {
    Button: {
      borderRadius: themeConstants.borderRadius,
      fontFamily: 'EDNimpkishRegular',
    },
    Input: {
      borderRadius: themeConstants.borderRadius,
    },
    Layout: {
      siderBg: themeConstants.colours.eclipseBlackDark,
    },
    Menu: {
      darkItemBg: themeConstants.colours.eclipseBlackDark,
    },
    Select: {
      borderRadius: themeConstants.borderRadius,
    },
  },
};
