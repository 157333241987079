/**
 * Formats an address object into a comma separated string
 * removing null values.
 *
 * @param {object} address An object documenting address fields.
 * @return {string} A comma separated string made up from the address values.
 */
export default (address) =>
  Object.keys(address)
    .map((key) => address[key])
    .filter((value) => value)
    .join(', ');
