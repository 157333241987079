const SET_BRANDS = 'SET_BRANDS';
const SET_CURRENT_BRAND = 'SET_CURRENT_BRAND';
const INITIAL_STATE = {
  brands: [],
  currentBrand: '',
};

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_BRANDS':
      return { ...state, brands: action.payload };
    case 'SET_CURRENT_BRAND':
      return { ...state, currentBrand: action.payload };
    default:
      return state;
  }
}

export const setBrands = (brands) => {
  localStorage.setItem('v1_brands', JSON.stringify(brands));

  return { type: SET_BRANDS, payload: brands };
};

export const setCurrentBrand = (currentBrand) => {
  localStorage.setItem('v1_current-brand', currentBrand);

  return { type: SET_CURRENT_BRAND, payload: currentBrand };
};
