export const brands = {
  sandbox: [
    { name: 'Airtime Rewards', id: '46195e7c-dee1-45ce-812b-fb29e66c59e8' },
    { name: 'Q-Park Rewards', id: '40eaafc1-904a-4cf3-8f9c-ba9df1b551d5' },
  ],
  uat: [
    { name: 'Airtime Rewards', id: '6849dcbd-dd3a-4c21-806e-d2d943fe1b2a' },
    { name: 'Q-Park Rewards', id: '688c1912-05ec-47af-a73b-6607eee58a12' },
  ],
  production: [
    { name: 'Airtime Rewards', id: '305f80b0-59e1-4943-917d-4606ee3bf3f7' },
    { name: 'Q-Park Rewards', id: 'a33e94ad-242b-4736-92f3-5befdae63789' },
  ],
}[process.env.REACT_APP_ZORRO_ENVIRONMENT];
