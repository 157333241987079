import { datadogRum } from '@datadog/browser-rum';

export default () =>
  datadogRum.init({
    applicationId: 'b24ca732-7c7b-44de-af8e-bb22e4b7c6c6',
    clientToken: 'pubc66d255b9d122ec86f76063417ed0fad',
    site: 'datadoghq.eu',
    service: 'ar-console',
    version: process.env.REACT_APP_VERSION || 'localhost',
    env: process.env.REACT_APP_ENVIRONMENT || 'localhost',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask',
  });
