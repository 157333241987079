import formatAddress from './formatAddress';

/**
 * Converts an array of zorro outlet objects into
 * an array of objects in the format needed for AntDesigns Select component.
 *
 * @param {Array} outlets An array of zorro outlet objects.
 * @return {Array} An array of objects with label(outletID) and value(outlet address) keys.
 */
export default (outlets) => {
  return outlets?.map((outlet) => {
    const {
      address: { line1, city, postcode },
    } = outlet;

    return {
      value: outlet.id,
      label: formatAddress({ line1, city, postcode }),
    };
  });
};
