// Tags
export const API_RESPONSE_MID_NOT_PROVISIONED = 'MID_NOT_PROVISIONED';
export const API_RESPONSE_OUTLET_INFORMATION_UNKNOWN =
  'OUTLET_INFORMATION_UNKNOWN';
export const API_RESPONSE_QUERY_TRANSACTION = 'QUERY_TRANSACTION';
export const API_RESPONSE_AGED_WRITE_OFF = 'AGED_WRITE_OFF';
export const API_RESPONSE_ADMIN_ERROR_WRITE_OFF = 'ADMIN_ERROR_WRITE_OFF';
export const API_RESPONSE_MID_NOT_TRACKING = 'MID_NOT_TRACKING';
export const API_RESPONSE_QUERIED_WITH_USER = 'QUERIED_WITH_USER';
export const API_RESPONSE_MID_PREVIOUSLY_TRACKED = 'MID_PREVIOUSLY_TRACKED';

// Feature flagging
export const IS_ONE_TIME_CARD_WITHDRAWALS_ENABLED = true;
