import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import App from './App';
import { unregister } from './registerServiceWorker';
import store from './store';
import { datadogRumInit, datadogRumStartSessionReplay } from './utilities';
import theme from './theme';

datadogRumInit();
datadogRumStartSessionReplay();

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider theme={theme}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

unregister();
