/**
 * A function that converts the payout_value amounts in
 * an array of reward target objects.
 *
 * @param {Array} targets An array of reward target objects.
 * @param {string} type Must be 'pounds' or 'pence'.
 * @return {Array} A new array with the payout_value amounts
 * converted to the relevant type.
 */
export default (targets, type) => {
  if (!Array.isArray(targets)) return [];

  return targets.map((target) => {
    let { amount } = target.payout_value;

    if (type === 'pounds') amount /= 100;
    if (type === 'pence') amount *= 100;

    return {
      ...target,
      payout_value: {
        ...target.payout_value,
        amount,
      },
    };
  });
};
