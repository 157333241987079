const SET_RETAIL_GROUPS = 'SET_RETAIL_GROUPS';
const SET_RETAIL_GROUPS_LOADING = 'SET_RETAIL_GROUPS_LOADING';
const INITIAL_STATE = {
  retailGroups: [],
  retailGroupsLoading: true,
};

// eslint-disable-next-line default-param-last
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_RETAIL_GROUPS':
      return { ...state, retailGroups: action.payload };
    case 'SET_RETAIL_GROUPS_LOADING':
      return { ...state, retailGroupsLoading: action.payload };
    default:
      return state;
  }
}

export const setRetailGroups = (retailGroups) => {
  return { type: SET_RETAIL_GROUPS, payload: retailGroups };
};

export const setRetailGroupsLoading = (loading) => {
  return { type: SET_RETAIL_GROUPS_LOADING, payload: loading };
};
