export const cardSchemes = [
  {
    label: 'Visa',
    value: 'VISA',
  },
  {
    label: 'Mastercard',
    value: 'MASTERCARD',
  },
  {
    label: 'Amex',
    value: 'AMEX',
  },
];
